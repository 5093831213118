import React from "react";
import Product from "./Product";


const ShopTwo = ()=>{
    return <>
    <Product shop="two"/>
    </>
}


export default ShopTwo