import React, { useEffect, useState } from "react";
import "./Purchase.css";
import { getProduct, registerProduct, soldProduct } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

import {  useDispatch, useSelector } from "react-redux";
import { addproducts } from "../../redux/product/ProductSlice";


const PurchaseMain = () => {

  const dispatch = useDispatch()
  const [dataProduct, setDataProduct] = useState({
    name: "",
    boxespercarton: "",
    piecesperbox: "",
    cartons: "",
    price: "",
    size: "",
    color:"",
    shop:"main",
    totalprice:"",
    priceperbox:"",
    priceperpiece:"",
    additionalboxes: ""
  });

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setShowMessage] = useState(false)
  const [isAlertFailed, setAlertFailed] = useState(false)
  const [isStockEnought, setIsStockEnought] = useState('')
 

  const [newFetch, setNewFetch] = useState(false)



  const handleChanges = (e) => {
    const { name, value } = e.target;

    // Trim the value if it is a string and the property is 'name' or potentially any string field
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    setDataProduct(prevData => {
        // Use the potentially trimmed value instead of the raw one
        const newData = { ...prevData, [name]: trimmedValue };

        // Additional logic for price and cartons calculation
        if (name === "price" || name === "cartons") {
            const totalprice = Number(newData.price) * Number(newData.cartons);
            const priceperbox = Number(newData.price) / Number(newData.boxespercarton);
            const priceperpiece = Number(newData.price) / (Number(newData.boxespercarton) * Number(newData.piecesperbox));
            return {...newData, totalprice, priceperbox, priceperpiece};
        }

        return newData;
    });
};


  useEffect(() => {
    getProduct().then((result) => {
      // const product = result.data.map((product) => ({name:product.name, size:product.size}));
      setProducts(result.data);
      // dispatch(addproducts(product))
    });
  }, [newFetch]);



  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    registerProduct(dataProduct)
      .then((res) => {
        if (res && res.status === 200) {
          setIsLoading(false)
          setShowMessage(true)

          setNewFetch(!newFetch)

          setTimeout(() => {
            setShowMessage(false)
          }, 5000)

          setDataProduct({
            name: "",
    boxespercarton: "",
    piecesperbox: "",
    cartons: "",
    price: "",
    size: "",
    shop:"main",
    totalprice:"",
    color:"",
    priceperbox:"",
    priceperpiece:"",
    additionalboxes:""
          })

          console.log("Successfully registered");
        } else {
          setAlertFailed(true)

          setTimeout(() => {
            setAlertFailed(false)
          }, 5000)
          console.log("Failed to register");
        }
      })
      .catch((error) => {
        setAlertFailed(true)

        setTimeout(() => {
          setAlertFailed(false)
        }, 5000)
        setIsLoading(false)
      });
  };

  return (
    <>
      <Sidebar />
      <div className="purchase">
        <h2 className="purchase-title">Register a Product in A Main Shop</h2>
        <div className="purchase-form">
          <form onSubmit={handleSubmit}>
          
           <div className="purchase-form__user_input">
              <label>Name of Product</label>

              <input
                type="text"
                name="name"
                value={dataProduct.name}
                onChange={handleChanges}
                autoComplete="off"
                required
              />
            </div>

            <div className="purchase-form__user_input">
              <label>Color</label>
              <input
                type="text"
                name="color"
                value={dataProduct.color}
                onChange={handleChanges}
                autoComplete="off"
                required
                
              />
            </div>

            <div className="purchase-form__user_input">
              <label>Size</label>
              <input
                type="number"
                name="size"
                value={dataProduct.size}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>


            <div className="purchase-form__user_input">
              <label>cartons</label>
              <input
                type="number"
                name="cartons"
                value={dataProduct.cartons}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}


              />
            </div>

            

            <div className="purchase-form__user_input">
              <label>boxes per carton</label>
              <input
                type="number"
                name="boxespercarton"
                value={dataProduct.boxespercarton}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>

            <div className="purchase-form__user_input">
              <label>pieces per box</label>
              <input
                type="number"
                name="piecesperbox"
                value={dataProduct.piecesperbox}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>

            <div className="purchase-form__user_input">
              <label>additional boxes <span style={{color: "red"}}>(Optional)</span></label>
              <input
                type="number"
                name="additionalboxes"
                value={dataProduct.additionalboxes}
                onChange={handleChanges}
                autoComplete="off"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            

            
            
            <div className="purchase-form__user_input">
              <label>Price Per Carton</label>
              <input
                type="number"
                name="price"
                value={dataProduct.price}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>

            <div className="purchase-form__user_input">
              <label>total price</label>
              <input
                type="number"
                name="totalprice"
                value={dataProduct.totalprice}
                onChange={handleChanges}
                autoComplete="off"
                required
                disabled
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>

            <button className="purchase-form__register_btn" type="submit" value="Register" >
              {isLoading ? (
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : "Register"}
            </button>


          </form>

          {isMessage &&
            <div className="purchase-message" class="alert alert-success" role="alert">
              Successfull saved in Main Stock
            </div>
          }
          {isAlertFailed &&
            <div className="purchase-message" class="alert alert-danger" role="alert">
              Failed to save, {isStockEnought}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default PurchaseMain;
