import React, { useEffect, useState } from "react";
import "./Purchase.css";
import { getProduct, registerProduct, soldProduct } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

import { useDispatch, useSelector } from "react-redux";
import { addproducts } from "../../redux/product/ProductSlice";


const Purchase = (props) => {

  const dispatch = useDispatch()
  const [dataProduct, setDataProduct] = useState({
    name: "",
    boxespercarton: "",
    piecesperbox: "",
    cartons: "",
    price: "",
    size: "",
    color:"",
    shop: "",
    totalprice: "",
    priceperbox: "",
    priceperpiece: "",
  });

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setShowMessage] = useState(false)
  const [isAlertFailed, setAlertFailed] = useState(false)
  const [errormessage, setErrorMessage] = useState("")


  const [newFetch, setNewFetch] = useState(false)



  const handleChanges = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value
    setDataProduct(prevDataProduct => {
      // Calculate the new state based on previous state and current input
      const newDataProduct = { ...prevDataProduct, [name]: value };

      // Log the current state of newDataProduct after this input
      console.log(newDataProduct);

      // Conditional logic based on the current input
      if (name === "size" && value !== "") {
        const mainproduct = products.filter(product => {
          return (
            product.name === newDataProduct.name &&
            product.shop === "main" &&
            product.size === Number(newDataProduct.size) &&
            product.color === value  // Use the direct value from e.target
          );
        });

        // If a matching product is found, update more state properties
        if (mainproduct.length > 0) {
          
          return {
            ...newDataProduct,
            price: mainproduct[0].price,
            priceperbox: mainproduct[0].priceperbox,
            priceperpiece: mainproduct[0].priceperpiece
          };
        }
      }

      // Return the updated state to be set
      return newDataProduct;
    });
};


  useEffect(() => {
    getProduct().then((result) => {
      // const product = result.data.map((product) => ({name:product.name, size:product.size}));
      setProducts(result.data);
      // dispatch(addproducts(product))
    });
  }, [newFetch]);



  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    registerProduct(dataProduct)
      .then((res) => {
        if (res && res.status === 200) {
          setIsLoading(false)
          setShowMessage(true)

          setNewFetch(!newFetch)

          setTimeout(() => {
            setShowMessage(false)
          }, 5000)

          setDataProduct({
            name: "",
            boxespercarton: "",
            piecesperbox: "",
            cartons: "",
            price: "",
            size: "",
            shop: "",
            totalprice: "",
            priceperbox: "",
            priceperpiece: ""
          })
        } else {
          setAlertFailed(true)


          console.log(res);

          setTimeout(() => {
            setAlertFailed(false)
          }, 5000)

        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);

        setAlertFailed(true)

        setTimeout(() => {
          setAlertFailed(false)
        }, 5000)
        setIsLoading(false)
      });
  };

  return (
    <>
      <Sidebar />
      <div className="purchase">
        <h2 className="purchase-title">Register a Product in A Shop</h2>
        <div className="purchase-form">
          <form onSubmit={handleSubmit}>

            <div className="purchase-form__user_input">
              <label>Choose the shop you're purchasing for</label>
              <select name="shop" value={dataProduct.shop} onChange={handleChanges}>
                <option>Select shop</option>
                <option value="shop 1">Shop 1</option>
                <option value="shop 2" >Shop 2</option>
              </select>

            </div>


            <div className="purchase-form__user_input">
  <label>Names of products in stock</label>
  <select name="name" value={dataProduct.name} onChange={handleChanges}>
    <option>Select your product</option>
    {products
      .filter(product => product.shop === "main")  // Filter products by shop
      .map(product => product.name)               // Extract names
      .filter((name, index, array) => array.indexOf(name) === index)  // Remove duplicates
      .map((productName, index) => (
        <option key={index} value={productName}>{productName}</option>
      ))
    }
  </select>
</div>




      


            <div className="purchase-form__user_input">
              <label>color</label>

              <select value={dataProduct.color} onChange={handleChanges} name="color">
    <option value="">Select Color</option>
    {products.filter((productesavailable) => (
        (productesavailable.name === dataProduct.name && productesavailable.shop === "main")
    )).map((product, index) => (
        <option key={index} value={product.color}>{product.color}</option>
    ))}
</select>

            </div>


            <div className="purchase-form__user_input">
              <label>size</label>

              <select value={dataProduct.size} onChange={handleChanges} name="size">
    <option value="selection">Select size</option>
    {products.filter((productesavailable) => (
        (productesavailable.name === dataProduct.name && productesavailable.shop === "main" && productesavailable.color == dataProduct.color)
    )).map((product, index) => (
        <option key={index} value={product.size}>{product.size}</option>
    ))}
</select>

            </div>

            



            <div className="purchase-form__user_input">
              <label>cartons</label>
              <input
                type="number"
                name="cartons"
                value={dataProduct.cartons}
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}


              />
            </div>

            

             

            <div className="purchase-form__user_input">
              <label>boxes per carton</label>
              <input
                type="number"
                name="boxespercarton"
                value={dataProduct.boxespercarton = products.filter(productesavailable => (
                  (productesavailable.name == dataProduct.name && productesavailable.shop == "main" && productesavailable.size == dataProduct.size && productesavailable.color == dataProduct.color)
                )).map((prod, index) => (
                  prod
                ))[0]?.boxespercarton
                }
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>

            <div className="purchase-form__user_input">
              <label>pieces per box</label>
              <input
                type="number"
                name="piecesperbox"
                value={dataProduct.piecesperbox = products.filter(productesavailable => (
                  (productesavailable.name == dataProduct.name && productesavailable.shop == "main" && productesavailable.size == dataProduct.size && productesavailable.color == dataProduct.color)
                )).map((prod) => (
                  prod
                ))[0]?.piecesperbox

                }
                onChange={handleChanges}
                autoComplete="off"
                required
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>




            <button className="purchase-form__register_btn" type="submit" value="Register" >
              {isLoading ? (
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                props.name !== "sell" ? 'Register' : 'Confirm'
              )}
            </button>


          </form>

          {isMessage &&
            <div className="purchase-message" class="alert alert-success" role="alert">
              Successfull registered
            </div>
          }
          {isAlertFailed &&
            <div className="purchase-message" class="alert alert-danger" role="alert">
              Failed to save, {errormessage}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Purchase;
