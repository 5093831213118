import React, { useEffect, useState } from 'react'
import Sidebar from '../../navs/Sidebar'

import { getProduct, soldProduct } from '../../services/Service';

const Sell = () => {


  const { _id } = JSON.parse(localStorage.getItem('currentUser'))
  const [formData, setFormData] = useState({
    adminId: _id,
    name: "",
    option: "",
    amount: 0,
    color: "",
    size: 0,
    status: "",
    price: 0,
    seller: "",
    shop: "",
    totalprice: 0
  })
  const [products, setProducts] = useState([])
  const [isAlertFailed, setAlertFailed] = useState(false)
  const [isStockEnought, setIsStockEnought] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [isMessage, setShowMessage] = useState(false)

  const [nameValidation, setnameValidation] = useState("")
  const [shopValidation, setshopValidation] = useState("")
  const [statusValidation, setstatusValidation] = useState("")
  const [sizeValidation, setsizeValidation] = useState("")
  const [optionValidation, setoptionValidation] = useState("")

  const [colorValidation, setcolorValidation] = useState("")
  const [qtyValidation, setqtyValidation] = useState("")
  const [priceUnitValidation, setpriceUnitValidation] = useState("")
  const [sellerValidation, setsellerValidation] = useState("")



  useEffect(() => {
    getProduct().then((result) => {
      // const product = result.data.map((product) => ({ name: product.name, size: product.size }));
      setProducts(result.data);
      // console.log(result.data);
    });
  }, []);

  const onChangeForm = event => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })

    name == "name" && value == "" ? setnameValidation("please choose a valid option") : setnameValidation("")
    name == "shop" && value== "" ? setshopValidation("please choose a valid option") : setshopValidation("")
    name == "status" && value == "" ? setstatusValidation("please choose a valid option") : setstatusValidation("")
    name == "size" && value == "" ? setsizeValidation("please choose a valid option") : setsizeValidation("")
    name == "option" && value == "" ? setoptionValidation("please choose a valid option") : setoptionValidation("")


    name == "color" && (value == "") ? setcolorValidation("Please enter valid color") : setcolorValidation("")
    name == "amount" && (value == 0 || value < 0) ? setqtyValidation("Please enter valid quantity") : setqtyValidation("")
    name == "price" && (value == 0 || value < 0) ? setpriceUnitValidation("Please enter valid price") : setpriceUnitValidation("")
    name == "seller" && (value == "") ? setsellerValidation("Please enter valid name") : setsellerValidation("")



  }

  const isFormValid = () => {
    return (
      nameValidation === "" &&
      shopValidation === "" &&
      colorValidation === "" &&
      sizeValidation === "" &&
      optionValidation === "" &&
      qtyValidation === "" &&
      priceUnitValidation === "" &&
      sellerValidation === ""
    );
  };


  const handleSubmitSold = (event) => {
    event.preventDefault();
    setIsLoading(true)
    soldProduct(formData)
      .then((res) => {
        if (res && res.status === 200) {
          setIsLoading(false)
          setShowMessage(true)

          setTimeout(() => {
            setShowMessage(false)
          }, 5000)

          ///////////////////////////////////////////////////////////// PLEASE REMEMBER TO CHANGE THIS SO THAT IT CLEARS ALL THE FORM DATA AFTER SUBMISSION AND VALIDATION
          setFormData({
            adminId: _id,
            name: "",
            option: "",
            amount: 0,
            color: "",
            size: 0,
            status: "",
            price: 0,
            seller: "",
            shop: "",
            totalprice: 0
          })

          console.log("Successfully registered");
        } else {
          setAlertFailed(true)

          setTimeout(() => {
            setAlertFailed(false)
          }, 5000)
          console.log("Failed to register");
        }
      })
      .catch((error) => {
        setAlertFailed(true)

        setTimeout(() => {
          setAlertFailed(false)
        }, 5000)
        setIsLoading(false)
        setIsStockEnought(error.response.data.message)
      });
  };


  return <>
    <Sidebar />
    <div className="purchase">
      <h2 className="purchase-title">Sell a Product</h2>
      <div className="purchase-form">
        <form onSubmit={handleSubmitSold}>

        <div className="purchase-form__user_input">
  <label>Name of products in stock</label>
  <select name='name' value={formData.name} onChange={onChangeForm}>
    <option value=''>Select your product</option>
    {
      [...new Set(products
        .filter(product => product.shop === "shop 1" || product.shop === "shop 2")
        .map(product => product.name)
      )]
      .map((name, index) => (
        <option key={index} value={name}>{name}</option>
      ))
    }
  </select>
  {nameValidation !== "" && <p style={{ color: "red" }}>{nameValidation}</p>}
</div>

<div className="purchase-form__user_input">
  <label>Choose the shop you're purchasing from</label>
  <select name="shop" value={formData.shop} onChange={onChangeForm}>
    <option value=''>Select shop</option>
    {
      [...new Set(products
        .filter(product => product.name === formData.name && product.shop !== "main")
        .map(product => product.shop)
      )]
      .map((shop, index) => (
        <option key={index} value={shop}>{shop}</option>
      ))
    }
  </select>
  {shopValidation !== "" && <p style={{ color: "red" }}>{shopValidation}</p>}
</div>

<div className="purchase-form__user_input">
  <label>Color</label>
  <select name='color' value={formData.color} onChange={onChangeForm}>
    <option value=''>Select a color</option>
    {
      [...new Set(products
        .filter(product => product.name === formData.name && product.shop === formData.shop && product.shop !== "main")
        .map(product => product.color)
      )]
      .map((color, index) => (
        <option key={index} value={color}>{color}</option>
      ))
    }
  </select> 
</div>




          <div className="purchase-form__user_input">
            <label>size</label>

            <select name="size" value={formData.size} onChange={onChangeForm}>
              <option value=''>Select size</option>
              {products.filter((productesavailable) => (
                (productesavailable.name == formData.name && productesavailable.shop == formData.shop && productesavailable.shop != "main" && productesavailable.color == formData.color)
              )).map((product, index) => (
                <option key={index} value={product.size}>{product.size}</option>
              ))
              }
            </select>
            {sizeValidation != "" && <p style={{ color: "red" }}>{sizeValidation}</p>}

          </div>

          <div className="purchase-form__user_input">
            <label value=''>Unit</label>
            <select onChange={onChangeForm} name='option' value={formData.option}>
              <option >select your option</option>
              <option value="cartons">Cartons</option>
              <option value="boxes">Boxes</option>
              <option value="pieces">Pieces</option>
            </select>
            {optionValidation != "" && <p style={{ color: "red" }}>{optionValidation}</p>}
          </div>



          <div className="purchase-form__user_input">
            <label>Quantity</label>
            <input
              type="number"
              name="amount"
              onChange={onChangeForm}
              value={formData.amount}
              autoComplete="off"
              required
              onWheel={(e) => e.currentTarget.blur()}
            />
            {qtyValidation != "" && <p style={{ color: "red" }}>{qtyValidation}</p>}

          </div>




          <div className="purchase-form__user_input">
            <label>price per unit</label>
            <input
              type="number"
              name="price"
              onChange={onChangeForm}
              value={formData.price}
              autoComplete="off"
              required
              onWheel={(e) => e.currentTarget.blur()}
            />
            {priceUnitValidation != "" && <p style={{ color: "red" }}>{priceUnitValidation}</p>}

          </div>


          <div className="purchase-form__user_input">
            <label>Total price</label>
            <input
              type="number"
              disabled
              name="totalprice"
              onChange={onChangeForm}
              value={formData.totalprice = Number(formData.price * Number(formData.amount))}
              autoComplete="off"
              required
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>

          <div className="purchase-form__user_input">
            <label>Buyer</label>
            <input
              type="text"
              name="seller"
              onChange={onChangeForm}
              value={formData.seller}
              autoComplete="off"
              required
            />
            {sellerValidation != "" && <p style={{ color: "red" }}>{sellerValidation}</p>}

          </div>
          <div className="purchase-form__user_input">
            <label>Pay status</label>
            <select onChange={onChangeForm} name='status' value={formData.status} required>
              <option value='' >select the payment status</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
            {statusValidation != "" && <p style={{ color: "red" }}>{statusValidation}</p>}
          </div>

          <button className={`purchase-form__register_btn ${isFormValid() ? '' : 'disablebtn'}`}
  type="submit"
  value="Register"
  disabled={!isFormValid()} >
            {isLoading ? (
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Sell'
            )}
          </button>

        </form>

        {isMessage &&
          <div className="purchase-message" class="alert alert-success" role="alert">
            Successfull sold
          </div>
        }
        {isAlertFailed &&
          <div className="purchase-message" class="alert alert-danger" role="alert">
            Failed to sell,{isStockEnought}
          </div>
        }
      </div>
    </div>

  </>
}

export default Sell
