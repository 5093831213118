import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Purchase from './pages/purchase/Purchase'
import Sell from './pages/sell/Sell'
import Product from './pages/product/Product'
import Transaction from './pages/transaction/Transaction'
import AddAdmin from './pages/add-admin/AddAdmin'
import ViewAdmin from './pages/view-admins/ViewAdmin'
import Login from './pages/login/Login'
import Protected  from './middleware/Protected'
import PurchaseMain from './pages/purchase/Purchase-main'
import ShopOne from './pages/product/Shop-one'
import ShopTwo from './pages/product/Shop-two'
import ProtectedSuperAdmin from './middleware/ProtectedAdmin'
import Transfers from './pages/transaction/Transfers'

const CustomRoutes = () => {
  return (
    <Routes >
      <Route exact path='/purchase' element={<ProtectedSuperAdmin><Purchase/></ProtectedSuperAdmin>} />
      <Route exact path='/purchase-main' element={<ProtectedSuperAdmin><PurchaseMain/></ProtectedSuperAdmin>}/>
      <Route exact path='/sell' element={<Protected><Sell/></Protected>} />
      <Route exact path='/product-main' element={<ProtectedSuperAdmin><Product/></ProtectedSuperAdmin>}/>
      <Route exact path='/product-shop1' element={<Protected><ShopOne/></Protected>}/>
      <Route exact path='/product-shop2' element={<Protected><ShopTwo/></Protected>}/>
      <Route exact path='/transaction' element={<Protected><Transaction/></Protected>}/>
      <Route exact path='/transfer' element={<Protected><Transfers/></Protected>}/>
      <Route exact path='/add-admin' element={<ProtectedSuperAdmin><AddAdmin/></ProtectedSuperAdmin>}/>
      <Route exact path='/view-admin' element={<ProtectedSuperAdmin><ViewAdmin/></ProtectedSuperAdmin>}/>
      <Route exact path='/' element={<Login/>}/>
    </Routes>
  )
}

export default CustomRoutes
