import axios from "axios";
import { BASE_URL } from "./config";

const headers = {
  "Content-Type": "application/json",
};

export const registerProduct = async (product) => {
  const response = await axios.post(`${BASE_URL}/product/register`, product, {
    headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    }
  });
  return response.data;
};

export const getProduct = async () => {
  const response = await axios.get(`${BASE_URL}/product/`, { headers:{
    "Content-Type": "application/json",
    Authorization: localStorage.getItem('token')
  } });
  return response.data;
};

export const soldProduct = async (product) => {
  const response = await axios.post(`${BASE_URL}/product/sold`, product, {
    headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    }
  });
  // console.log(response)
  return response.data;
};

export const updateProduct = async (id, product) => {
  const response = await axios.patch(
    `${BASE_URL}/product/update/${id}`,
    product,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
};

export const deleteProduct = async (id) => {
  const response = await axios.delete(`${BASE_URL}/product/delete/${id}`, {
    headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    }
  });
  return response.data;
};

export const getAllTransaction = async () => {
  const response = await axios.get(`${BASE_URL}/product/all-transactions`, {
    headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response.data;
};

export const returnProductToStock = async (id) => {
  const response = await axios.get(`${BASE_URL}/product/returnProductToShop/${id}`, {
    headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response.data;
};

export const addAdmin = async (admin) => {
  const response = await axios.post(
    `${BASE_URL}/superadmin/register-admin`,
    admin,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
};

export const getAllAdmin = async () => {
  const response = await axios.get(`${BASE_URL}/superadmin/all-admins`, {
    headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    }
  });
  return response.data;
};

export const updateAdmin = async (id, admin) => {
  const response = await axios.patch(
    `${BASE_URL}/superadmin/update-admin/${id}`,
    admin,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
};

export const updateAdminPassword = async(id, newPassword)=>{
  const response = await axios.patch(
    `${BASE_URL}/superadmin/update-admin-password/${id}`,
    newPassword,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
}

// /update-transaction/:id'

export const updateTransaction = async(id, paymentupdate)=>{
  const response = await axios.patch(
    `${BASE_URL}/product/update-transaction/${id}`,
    paymentupdate,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
}

export const deleteAdmin = async (id) => {
  const response = await axios.delete(
    `${BASE_URL}/superadmin/delete-admin/${id}`,
    { headers:{
      "Content-Type": "application/json",
      Authorization: localStorage.getItem('token')
    } }
  );
  return response.data;
};

export const login = async (credentials) => {
  const response = await axios.post(
    `${BASE_URL}/superadmin/login`,
    credentials,
    { headers }
  );
  return response.data;
};

export const getAdminProfile = async (token) => {
    const response = await axios.get(`${BASE_URL}/superadmin/profile`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};

export const transferProduct = async(product) =>{
  const response = await axios.post(`${BASE_URL}/product/transfer-product`, product, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
    }
});
return response.data;
}
