import React, { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Dropdown,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "./Product.css";
import {
  deleteProduct,
  getProduct,
  transferProduct,
  updateProduct,
} from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

const Product = (props) => {
  const [authorized, setAuthorized] = useState(true)
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(100000000);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({
    name: "",
    size: "",
    color: "",
    option: "",
    quantity: 0,
    price: 0,
    boxespercarton: 0,
    piecesperbox: 0
  });
  const [transferMessage, setTransferMessage] = useState("")

  const [transferData, setTransferData] = useState({
    option:"",
    amount: 0,
    shop: `${props.shop=="one"?"shop 2": "shop 1"}`,
    size: 0,
    name: "",
    color: ""
  })
  const [showModalTransfer, setShowModalTransfer] =  useState(false)
  
  const [afterUpdateDelete, setafterUpdateDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getProduct().then((result) => {
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        if(props.shop == "one"){
          const res = result.data.filter((productsShopOne)=>(
            productsShopOne.shop == "shop 1"
          ))
          return setProducts(res)
        }
        if(props.shop == "two"){
          const res = result.data.filter((productsShopOne)=>(
            productsShopOne.shop == "shop 2"
          ))
          return setProducts(res)
        }
        const res = result.data.filter((productsShopOne)=>(
          productsShopOne.shop == "main"
        ))
        return setProducts(res)
      }
    });
  }, [afterUpdateDelete]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleUpdateModal = (product) => {
    setSelectedProduct(product);
    setUpdatedProduct({
      ...updatedProduct,
      ["name"]: product.name,
      ["boxespercarton"]: product.boxespercarton,
      ["piecesperbox"]: product.piecesperbox,
      ["price"]: product.price,
      ["size"]: product.size,
      ["color"]: product.color,
      ["quantity"]: updatedProduct.option == "cartons"? product.cartons : updatedProduct.option == "boxes" ? (product.boxes - (product.cartons * product.boxespercarton)) : updatedProduct.option == "pieces" ? (product.pieces - (product.boxes * product.piecesperbox)) : ""

    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setUpdatedProduct({
      name: "",
      size: "",
      color: "",
      option: "",
      quantity: 0,
      price: 0,
      boxespercarton: 0,
      piecesperbox: 0
    })
  };

  const handleChangeModalForm = (event) => {
    const { name, value } = event.target;
  
    
    if (name === "option") {
      let quantity = "";
      if (value === "cartons") {
        quantity = selectedProduct.cartons;
      } else if (value === "boxes") {
        quantity = selectedProduct.boxes - (selectedProduct.cartons * selectedProduct.boxespercarton);
      } else if (value === "pieces") {
        quantity = selectedProduct.pieces - (selectedProduct.boxes * selectedProduct.piecesperbox);
      }
      setUpdatedProduct({ ...updatedProduct, [name]: value, quantity: quantity });
    } else {
      
      setUpdatedProduct({ ...updatedProduct, [name]: value });
    }

  };
  ;

  const handleUpdateProduct = (id) => {
    updateProduct(id, updatedProduct)
      .then((res) => {
        if (res.status == 200) {
          setafterUpdateDelete(!afterUpdateDelete);
          setUpdatedProduct({
            name: "",
            size: "",
            color: "",
            option: "",
            quantity: 0,
            price: 0,
            boxespercarton: 0,
            piecesperbox: 0
          })
          setShowModal(false);
        }
      })
      .catch((error) => {
        if(error.response.data.status == 401){
          setAuthorized(false);
          setShowModal(false)
          setTimeout(() => {
            setAuthorized(true);
          }, 4000);
        }
        
      });
  };

  const handleDeleteProduct = (productId) => {
    deleteProduct(productId)
      .then((res) => {
        if (res.status == 200) {
          setafterUpdateDelete(!afterUpdateDelete);
        }
      })
      .catch((error) => {
        if(error.response.data.status == 401){
          setAuthorized(false);
          setTimeout(() => {
            setAuthorized(true);
          }, 4000);
        }
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = currentProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTransferChange = (event)=>{
    const {name, value} = event.target
    setTransferData(prevData=>(
       {...prevData, [name]: value}
    ))

    console.log(transferData);
  }

  const handleTransferProduct = (transferproduct)=>{
    setShowModalTransfer(true)
    setTransferData({...transferData, ["name"]:transferproduct.name, ["size"]: transferproduct.size, ["color"]:transferproduct.color})
  }

  const handleCloseModalTransfer = ()=>{
    setShowModalTransfer(false)
  }

  const handleTransferData = ()=>{
    transferProduct(transferData)
      .then((res) => {
        if (res.status == 200) {
          setShowModalTransfer(false)
          setafterUpdateDelete(!afterUpdateDelete);
          setTransferData({
            option:"",
    amount: 0,
    size: 0,
    name: ""
          })
        }
      })
      .catch((error) => {
        if(error.response.data.status !=200){
          setTransferMessage(error.response.data.message)
        }
      });
  }

  return (
    <>
    <Sidebar/>
    <div className="product">
      {isLoading ? (
        <div className="spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="content">
          {!authorized && <h6 style={{color:"red"}}>Please you are not authorized, contact superadmin</h6>}
          <h2 className="purchase-title">List of Products</h2>

          <Form.Control
            className="w-auto"
            style={{ marginBottom: "10px" }}
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Shop</th>
                <th>Item</th>
                <th>Color</th>
                <th>Size</th>
                <th>Quantity</th>
                
                
                <th>boxes per carton</th>
                <th>pieces per box</th>
               {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&& <th> Price per Carton</th>}
                {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&&<th>Total Price</th>}
                <th>Created on</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.shop}</td>
                  <td>{product.name}</td>
                  <td>
                    {product.color}
                  </td>
                  <td>
                    {product.size}
                  </td>
                  
                  <td>{`${product.cartons} cartons ${product.boxes - (product.cartons * product.boxespercarton)} boxes ${product.pieces - (product.boxes * product.piecesperbox)} pieces` }</td>
                 
                 
                  <td>
                    {product.boxespercarton}
                  </td>
                  <td>
                    {product.piecesperbox}
                  </td>
                  {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&&<td>{product.price}</td>}
                  {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&&<td>{product.totalprice}</td>}
                 
                  <td>{formatDate(product.createdAt)}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="product-form__btn__handledeleteandupdate"
                        id="dropdown-basic"
                      ></Dropdown.Toggle>

                      <Dropdown.Menu>
                        {(props.shop!="one" || props.shop!="two") && <Dropdown.Item
                          onClick={() => handleUpdateModal(product)}
                        >
                          Update
                        </Dropdown.Item>}

                        {(props.shop=="one" || props.shop=="two")&&<Dropdown.Item
                          onClick={()=>handleTransferProduct(product)}
                        >
                          Transfer
                        </Dropdown.Item>}
                        <Dropdown.Item
                          onClick={() => handleDeleteProduct(product["_id"])}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                
              ))}
              {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&& <td colSpan="9">total price</td>}
              {JSON.parse(localStorage.getItem('currentUser')).role=='superadmin'&& <td>{filteredProducts.reduce((acc, price)=>{
                return acc + price.totalprice
              },0)}</td>}
            </tbody>
          </Table>
          <Pagination>
            {Array.from({
              length: Math.ceil(products.length / productsPerPage),
            }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Update Product</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="productName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          autoComplete="off"
          type="text"
          placeholder="Enter product name"
          name="name"
          onChange={handleChangeModalForm}
          value={updatedProduct.name}
        />
        
        <Form.Label>Size</Form.Label>
        <Form.Control
          type="Number"
          name="size"
          onChange={handleChangeModalForm}
          placeholder="Enter size"
          value={updatedProduct.size}
        />
        
        <Form.Label>Color</Form.Label>
        <Form.Control
          type="String"
          name="color"
          onChange={handleChangeModalForm}
          placeholder="Enter color"
          value={updatedProduct.color}
        />

        
        <Form.Label>Price Per Carton</Form.Label>
        <Form.Control
          type="Number"
          name="price"
          onChange={handleChangeModalForm}
          placeholder="Enter Price Per Carton"
          value={updatedProduct.price}
        />
        
        <Form.Label>Option</Form.Label><br/>
        <Form.Select name="option" value={updatedProduct.option} onChange={handleChangeModalForm}>
          <option value="">Select Option To Update</option> 
          <option value="cartons">Cartons</option> 
          <option value="boxes">Boxes</option> 
          <option value="pieces">Pieces</option> 
          <option value="quantity">Quantity</option>
        </Form.Select><br/>

        

        {updatedProduct.option === "cartons" && (
          <>
            <Form.Label>Cartons</Form.Label>
            <Form.Control
              type="Number"
              name="quantity"
              onChange={handleChangeModalForm}
              placeholder="Enter New Cartons"
              value={updatedProduct.quantity}
            />
          </>
        )}

        {updatedProduct.option === "boxes" && (
          <>
            <Form.Label>Boxes</Form.Label>
            <Form.Control
              type="Number"
              name="quantity"
              onChange={handleChangeModalForm}
              placeholder="Enter New Boxes"
              value={updatedProduct.quantity}
            />
          </>
        )}

        {updatedProduct.option === "pieces" && (
          <>
            <Form.Label>Pieces</Form.Label>
            <Form.Control
              type="Number"
              name="quantity"
              onChange={handleChangeModalForm}
              placeholder="Enter New Pieces"
              value={updatedProduct.quantity}
            />
          </>
        )}


{updatedProduct.option === "quantity" &&(
 <>
<Form.Label>Boxes Per Carton</Form.Label>
        <Form.Control
          type="Number"
          name="boxespercarton"
          onChange={handleChangeModalForm}
          placeholder="Enter Boxes Per Carton"
          value={updatedProduct.boxespercarton}
        /> 
        </>
        )}

        {updatedProduct.option === "quantity" &&(
         <>
        <Form.Label>Pieces Per Box</Form.Label>
        <Form.Control
          type="Number"
          name="piecesperbox"
          onChange={handleChangeModalForm}
          placeholder="Enter Pieces Per Box"
          value={updatedProduct.piecesperbox}
        />
        </>
        )}

        
        
      </Form.Group>
      
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>
    <Button
      className="product-form__btn__handleclosemodal"
      onClick={() => handleUpdateProduct(selectedProduct["_id"])}
    >
      Save changes
    </Button>
  </Modal.Footer>
</Modal>


      

      
      <Modal show={showModalTransfer} onHide={handleCloseModalTransfer}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer to {props.shop=="one"? "shop 2":"shop 1"} </Modal.Title>
        </Modal.Header>
        <h6 style={{color:"green", padding:"0rem 0.5rem"}}>NB: Make sure you have in {props.shop=="one"? "shop 2":"shop 1"} product of the same(name, size)</h6>

        <Modal.Body>
          <Form>
            <Form.Group controlId="transfeName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Enter product name"
                name="name"
                onChange={handleTransferChange}
                value={transferData.name}
                disabled
              />
              
              <Form.Label>Size</Form.Label>
              <Form.Control
                type="Number"
                name="size"
                onChange={handleTransferChange}
                placeholder="Enter size"
                value={transferData.size}
                disabled
              />

              <Form.Label>Color</Form.Label>
              <Form.Control
                type="text"
                name="color"
                onChange={handleTransferChange}
                placeholder="Enter Color"
                value={transferData.color}
                disabled
              />

              <Form.Label>Enter the Quantity to transfer</Form.Label>
              <Form.Control
                type="Number"
                name="amount"
                onChange={handleTransferChange}
                placeholder="Enter Quantity to transfer"
                value={transferData.amount}
              />

              <Form.Label>Unit To Transfer(e.g : boxes)</Form.Label> <br />
              <Form.Select style={{width:"100%",padding:"0.3rem"}} name="option" value={transferData.option} onChange={handleTransferChange}>

                <option >select option</option>
                <option value="cartons">Cartons</option>
                <option value="boxes">Boxes</option>
                <option value="pieces">Pieces</option>
              </Form.Select>
             
              
            </Form.Group>
          </Form>
        </Modal.Body>
        {transferMessage!="" && <h6 style={{color:"red", textAlign:"center"}}>{transferMessage}</h6>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalTransfer}>
            Close
          </Button>
          <Button
            className="product-form__btn__handleclosemodal"
            onClick={handleTransferData}
          >
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>





    </div>
    </>
  );
};

export default Product;
