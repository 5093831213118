import React, { useState } from "react";
import "./AddAdmin.css";
import { addAdmin } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

const AddAdmin = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleChanges = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmission = (event) => {
    try {
        event.preventDefault()
        setIsLoading(true)
      addAdmin(formData).then((res) => {
        if (res.status == 200) {
            setIsLoading(false)
          setIsSuccess(true);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          });
          setTimeout(() => {
            setIsSuccess(false);
          }, 5000);
          return console.log("successfull saved");
        }
        setIsFail(true);
        return console.log("failed");
      });
    } catch (error) {
      setIsFail(true);
      console.log(error);
    }
  };

  return (
    <>
    <Sidebar/>
    <div className="addadmin">
      <h2 className="purchase-title">Add Admin</h2>
      <form className="addadmin__form" onSubmit={handleSubmission}>
        <div className="addadmin__data">
          <label>Firstname</label>

          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChanges}
            autoComplete="off"
            required
          />
        </div>

        <div className="addadmin__data">
          <label>Lastname</label>

          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChanges}
            autoComplete="off"
            required
          />
        </div>
        <div className="addadmin__data">
          <label>Email</label>

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChanges}
            autoComplete="off"
            autoSave="off"
            required
          />
        </div>
        <div className="addadmin__data">
          <label>Password</label>

          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChanges}
            autoComplete="off"
            required
          />
        </div>

        <button className="addadmin-form__btn" disabled={isLoading} >{isLoading ? <div class="spinner-grow" role="status">
  <span class="sr-only">Loading...</span>
</div> : 'Register Admin'} </button>
      </form>

      {isSuccess && (
        <div
          className="purchase-message"
          class="alert alert-success"
          role="alert"
        >
          Successfull saved !!
        </div>
      )}
      {isFail && (
        <div
          className="purchase-message"
          class="alert alert-danger"
          role="alert"
        >
          Failed to save
        </div>
      )}
    </div>
    </>
  );
};

export default AddAdmin;
