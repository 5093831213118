import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import "./Sidebar.css";
import {
  FaShoppingCart,
  FaMoneyBillAlt,
  FaExchangeAlt,
  FaUserCog,
  FaSignOutAlt,
  FaTag,
  FaChevronDown,
  FaChevronUp,
  FaDashcube,
  FaBars,
  FaClosedCaptioning,
  FaTimes,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState(null);
  const [isSelected, setIsSelected] = useState(false)
  const [isSelectedTransaction, setisSelectedTransaction] = useState(false)
  const [isSelectedProduct, setisSelectedProduct] = useState(false)
  const [isSelectedPurchase, setisSelectedPurchase] = useState(false)
  const [detailbutton, setDetailsbutton] = useState(true)
  const [showHamburger, setShowHamburger] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setShowHamburger(false);
      } else {
        setShowHamburger(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  const currentUser = JSON.parse(localStorage.getItem('currentUser'))

  return (
    <nav className={!detailbutton ? "sidebar-nav": "sidebar-nav inactive"}>
      <div className="sidebar-nav-container">
        <div className="sidebar-nav__profile">
          <span className="sidebar-nav__profile__firstname">{currentUser.firstName[0]}</span>
          <span className="sidebar-nav__profile__secondname">{currentUser.lastName[0]}</span>
        </div>
        {showHamburger && (detailbutton ? <div className="sidebar-nav__button" onClick={()=>setDetailsbutton(!detailbutton)}><button><FaBars/></button></div>: <div className="sidebar-nav__button" onClick={()=>setDetailsbutton(!detailbutton)}><button><FaTimes/></button></div>)}
      </div>

      {<ul className={detailbutton ? "sidebar-ul": "sideb"}>
        
      {currentUser.role=="superadmin" && <li onClick={() => setisSelectedPurchase(!isSelectedPurchase)}>
          <Link

            className={`sidebar-nav__item  ${activeItem === "purchase" ? "active" : ""
              }`}
            onClick={() => setActiveItem("purchase")}  
          >
            <FaUserCog /> Purchase {!isSelected ? <FaChevronDown /> : <FaChevronUp />}
          </Link>
        </li>}

        {isSelectedPurchase && <li>
          <Link
            to="/purchase-main"
            className={`sidebar-nav__item sidebarpurchase ${activeItem === "main" ? "active" : ""
              }`}
            onClick={() => setActiveItem("main")} 
          >
            Main
          </Link>
        </li>}

        {isSelectedPurchase && <li>
          <Link
            to="/purchase"
            className={`sidebar-nav__item sidebarpurchase ${activeItem === "shops" ? "active" : ""
              }`}
            onClick={() => setActiveItem("shops")} 
          >
            Shops
          </Link>
        </li>}



        <li>
          <Link
            to="/sell"
            className={`sidebar-nav__item ${activeItem === "sell" ? "active" : ""
              }`}
            onClick={() => setActiveItem("sell")} 
          >
            <FaMoneyBillAlt /> Sell
          </Link>
        </li>

        




        <li onClick={() => setisSelectedProduct(!isSelectedProduct)}>
          <Link

            className={`sidebar-nav__item  ${activeItem === "product" ? "active" : ""
              }`}
            onClick={() => setActiveItem("product")} 
          >
            <FaUserCog /> Product {!isSelected ? <FaChevronDown /> : <FaChevronUp />}
          </Link>
        </li>

        {(currentUser.role=="superadmin" && isSelectedProduct) && <li>
          <Link
            to="/product-main"
            className={`sidebar-nav__item sidebarproduct ${activeItem === "product-main" ? "active" : ""
              }`}
            onClick={() => setActiveItem("product-main")} 
          >
            main
          </Link>
        </li>}

        {isSelectedProduct && <li>
          <Link
            to="/product-shop1"
            className={`sidebar-nav__item sidebarproduct ${activeItem === "product-shop-one" ? "active" : ""
              }`}
            onClick={() => setActiveItem("product-shop-one")} 
          >
            Shop 1
          </Link>
        </li>}
        {isSelectedProduct && <li>
          <Link
            to="/product-shop2"
            className={`sidebar-nav__item sidebarproduct ${activeItem === "product-shop-two" ? "active" : ""
              }`}
            onClick={() => setActiveItem("product-shop-two")} 
          >
            Shop 2
          </Link>
        </li>}





        {/* <li onClick={() => setIsSelected(!isSelectedTransaction)}>
          <Link
            to="/transaction"
            className={`sidebar-nav__item ${activeItem === "transaction" ? "active" : ""
              }`}
            onClick={() => setActiveItem("transaction")} 
          >
            <FaExchangeAlt /> Transaction{!isSelectedTransaction ? <FaChevronDown /> : <FaChevronUp />}
          </Link>
        </li> */}


        




        <li onClick={() => setisSelectedTransaction(!isSelectedTransaction)}>
          <Link

            className={`sidebar-nav__item ${activeItem === "transaction" ? "active" : ""
              }`}
            onClick={() => setActiveItem("transaction")} 
          >
            <FaExchangeAlt /> Transaction {!isSelectedTransaction ? <FaChevronDown /> : <FaChevronUp />}
          </Link>
        </li>

        {isSelectedTransaction && <li>
          <Link
            to="/transaction"
            className={`sidebar-nav__item admin ${activeItem === "add-admin" ? "active" : ""
              }`}
            onClick={() => setActiveItem("add-admin")} 
          >
            Transactions
          </Link>
        </li>}

        {isSelectedTransaction && <li>
          <Link
            to="/transfer"
            className={`sidebar-nav__item admin ${activeItem === "view-admin" ? "active" : ""
              }`}
            onClick={() => setActiveItem("view-admin")} 
          >
            Transfers
          </Link>
        </li>}










        {currentUser.role=="superadmin" && <li onClick={() => setIsSelected(!isSelected)}>
          <Link

            className={`sidebar-nav__item ${activeItem === "admins" ? "active" : ""
              }`}
            onClick={() => setActiveItem("admins")} 
          >
            <FaUserCog /> Admins {!isSelected ? <FaChevronDown /> : <FaChevronUp />}
          </Link>
        </li>}

        {isSelected && <li>
          <Link
            to="/add-admin"
            className={`sidebar-nav__item admin ${activeItem === "add-admin" ? "active" : ""
              }`}
            onClick={() => setActiveItem("add-admin")} 
          >
            Add admins
          </Link>
        </li>}

        {isSelected && <li>
          <Link
            to="/view-admin"
            className={`sidebar-nav__item admin ${activeItem === "view-admin" ? "active" : ""
              }`}
            onClick={() => setActiveItem("view-admin")} 
          >
            View admins
          </Link>
        </li>}

        
        <li onClick={() => {
          localStorage.clear()
          navigate('/')

        }}>
          <Link
            to="/logout"
            className={`sidebar-nav__item  ${activeItem === "logout" ? "active" : ""
              }`}
            onClick={() => setActiveItem("logout")}
          >
            <FaSignOutAlt /> Logout
          </Link>
        </li>

        
      </ul>}
    </nav>
  );
};

export default Sidebar;







