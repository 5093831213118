import React, { useEffect, useState } from "react";
import { Table, Spinner, Pagination, Form, Button, Dropdown, Modal, Toast, ToastContainer } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./Transaction.css";
import { getAllTransaction, returnProductToStock, updateTransaction } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";
import { FaCartPlus, FaArrowLeft } from "react-icons/fa";


const Transaction = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [formPaymentData, setformPaymentData] = useState({
    status: ""
  })
  const [selectedTransaction, setselectedTransaction] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [authorized, setAuthorized] = useState(true)
  const [toast, setShowtoast] = useState(false)

  const handleChangeStatus = (event) => {
    const { name, value } = event.target
    setformPaymentData(prevData => {
      return { ...prevData, [name]: value }
    })
  }




  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleUpdateModal = (transaction) => {
    setShowModal(true)
    setselectedTransaction(transaction)
    setformPaymentData(prevData => {
      return { ...prevData, ["status"]: transaction.status }
    })
  }

  const handleUpdatePaymentStatus = () => {
    updateTransaction(selectedTransaction._id, formPaymentData)
      .then((res) => {
        if (res.status === 200) {
          setRefresh(!refresh);
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setShowModal(false)
          setAuthorized(false);
          setTimeout(() => {
            setAuthorized(true);
          }, 4000);
        } else {
          // console.error("An error occurred:", error);
          // Handle other types of errors here if needed
        }
      });
  };


  const handleReturnProduct = (transaction)=>{
    setselectedTransaction(transaction)
    returnProductToStock(transaction._id)
      .then((res) => {
        if (res.status === 200) {
          setShowtoast(true)
          
          setRefresh(!refresh);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setAuthorized(false);
          setTimeout(() => {
            setAuthorized(true);
          }, 4000);
        } else {
          // console.error("An error occurred:", error);
          // Handle other types of errors here if needed
        }
      });
  }



  useEffect(() => {
    setIsLoading(true);
    getAllTransaction().then((result) => {
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        const transactionResult = result.data.filter(transaction=>(
           (transaction.seller!=undefined && transaction.status!=undefined && (transaction.transfer==undefined||transaction.transfer==null))
         ) )
        setTransactions(transactionResult);
        // console.log(result.data);
      }
    });
  }, [refresh]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  let filteredProducts = [...transactions];

  if (startDate && endDate) {
    // If a date range is selected, filter transactions based on the date range
    filteredProducts = filteredProducts.filter((transaction) => {
      const createdAtDate = new Date(transaction.createdAt);
      return createdAtDate >= startDate && createdAtDate <= endDate;
    });
  }

  // Aggregating transactions by name and calculating total price
  const aggregatedTransactions = filteredProducts.reduce((acc, transaction) => {
    if (!acc[transaction.name]) {
      acc[transaction.name] = {
        name: transaction.name,
        totalprice: 0,
        details: [],
      };
    }
    acc[transaction.name].totalprice += transaction.totalprice;
    acc[transaction.name].details.push(transaction);
    return acc;
  }, {});

  // If no date range is selected, calculate total prices based on all transactions
  const currentProducts = Object.values(aggregatedTransactions).slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };

  const handleNameClick = (name) => {
    setSelectedName(name);
  };

  const handleBackClick = () => {
    setSelectedName(null);
  };

  const toggleShow = () => setShowtoast(!toast);

  const formatQuantity = (transaction) => {
    return transaction.cartons ? `${transaction.cartons} cartons` :
           transaction.boxes ? `${transaction.boxes} boxes` :
           transaction.pieces ? `${transaction.pieces} pieces` : 'N/A'; // N/A if none are available
  };
  

  // Add fallback to an empty array to ensure consistency in data type
  const csvData = selectedName !== null
  ? (startDate && endDate
    ? // When a date range is selected and a name is selected
      filteredProducts.map(filterdata => ({
        date: formatDate(filterdata.createdAt),
        name: filterdata.name,
        color: filterdata.color,
        size: filterdata.size,
        sold_Quantity: formatQuantity(filterdata),
        price: filterdata.price,
        totalprice: filterdata.totalprice,
        buyer: filterdata.seller,
        payment_status: filterdata.status,
        shop: filterdata.shop,
        doneBy: `${filterdata.adminId?.firstName} ${filterdata.adminId?.lastName}`
      }))
    : // When no specific date range is selected but a name is selected
      (aggregatedTransactions[selectedName]?.details ?? []).map(filterdata => ({
        date: formatDate(filterdata.createdAt),
        name: filterdata.name,
        color: filterdata.color,
        size: filterdata.size,
        sold_Quantity: formatQuantity(filterdata),
        price: filterdata.price,
        totalprice: filterdata.totalprice,
        buyer: filterdata.seller,
        payment_status: filterdata.status,
        shop: filterdata.shop,
        doneBy: `${filterdata.adminId?.firstName} ${filterdata.adminId?.lastName}`
      }))
  )
  : // When no specific name is selected
    transactions.map(filterdata => ({
      date: formatDate(filterdata.createdAt),
      name: filterdata.name,
      color: filterdata.color,
      size: filterdata.size,
      sold_Quantity: formatQuantity(filterdata),
      price: filterdata.price,
      totalprice: filterdata.totalprice,
      buyer: filterdata.seller,
      payment_status: filterdata.status,
      shop: filterdata.shop,
      doneBy: `${filterdata.adminId?.firstName} ${filterdata.adminId?.lastName}`
    }));




  return (
    <>
      <Sidebar />
      <div className="transaction">
        {isLoading ? (
          <div className="transaction-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="transaction-content">
            {!authorized && <h6 style={{ color: "red" }}>Please you are not authorized, contact superadmin</h6>}

            {toast && <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 11 }}>
      <Toast show={toast} onClose={toggleShow} delay={5000} autohide>
        <Toast.Header>
          <strong className="me-auto">Success</strong>
          <button type="button" className="btn-close" onClick={toggleShow}></button>
        </Toast.Header>
        <Toast.Body>Successfully returned to {selectedTransaction.shop}</Toast.Body>
      </Toast>
      <button className="btn btn-primary" onClick={toggleShow}>Show Toast</button>
    </ToastContainer>}

            <h2 className="purchase-title">List of Transaction</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <div className="button-container">
                <Button variant="success" className="mr-2">
                <CSVLink
  data={csvData}
  filename={`transactions_${selectedName ? selectedName : 'all'}_${startDate ? startDate.toISOString().slice(0, 10) : 'start'}_${endDate ? endDate.toISOString().slice(0, 10) : 'end'}.csv`}
  className="btn btn-success">
  Download CSV
</CSVLink>

                </Button>
              </div>
              <Form.Group controlId="dateRangePicker">
                <Form.Label>Date Range:</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  placeholderText="Date Range"

                />
              </Form.Group>

            </div>

            {
  selectedName ? (
    
    <div>
      <Button onClick={handleBackClick} className="back-button">
        Back
      </Button>
      {aggregatedTransactions[selectedName] ? (
        <Table striped bordered hover id="table-to-xls">
          <thead>
            <tr>
              <th>No</th>
              <th>Date</th>
              <th>Name</th>
              <th>Color</th>
              <th>Size</th>
              <th>Sold Quantity</th>
              <th>Price Per Unit</th>
              <th>Total Price</th>
              <th>Buyer</th>
              <th>Payment Status</th>
              <th>Shop</th>
              <th>Done By</th>
              <th>Action</th>
              <th>Return</th>
            </tr>
          </thead>
          <tbody>
            {aggregatedTransactions[selectedName].details.map((transaction, index) => (
              <tr key={transaction._id}>
                <td>{index + 1}</td>
                <td>{formatDate(transaction.createdAt)}</td>
                <td>{transaction.name}</td>
                <td>{transaction.color}</td>
                <td>{transaction.size}</td>
                <td>
                  {transaction.cartons ? `${transaction.cartons} cartons` :
                    transaction.boxes ? `${transaction.boxes} boxes` :
                    `${transaction.pieces} pieces`}
                </td>
                <td>{transaction.price}</td>
                <td>{transaction.totalprice}</td>
                <td>{transaction.seller}</td>
                <td style={{ color: transaction.status === "unpaid" ? "red" : "inherit" }}>
                  {transaction.status}
                </td>
                <td>{transaction.shop}</td>
                <td>{`${transaction.adminId?.firstName} ${transaction.adminId?.lastName}`}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle className="product-form__btn__handledeleteandupdate" id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleUpdateModal(transaction)}>
                        Update
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td onClick={() => handleReturnProduct(transaction)} style={{ cursor: "pointer" }}>
                  <FaArrowLeft/>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="7">Total Sales</td>
              <td>{aggregatedTransactions[selectedName].details.reduce((acc, cur) => acc + cur.totalprice, 0)}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <div>No details available for {selectedName}</div>
      )}
    </div>
  ) : (
    <Table striped bordered hover id="table-to-xls">
      <thead>
        <tr>
          <th>Name</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        {currentProducts.map((transaction) => (
          <tr key={transaction.name} onClick={() => handleNameClick(transaction.name)}>
            <td>{transaction.name}</td>
            <td>{transaction.totalprice}</td>
          </tr>
        ))}
        <tr>
          <td>Total Sales</td>
          <td>{currentProducts.reduce((acc, cur) => acc + cur.totalprice, 0)}</td>
        </tr>
      </tbody>
    </Table>
  )
}

            <Pagination>
              {Array.from({
                length: Math.ceil(
                  Object.values(aggregatedTransactions).length /
                  productsPerPage
                ),
              }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Payment Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="adminName">


                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  onChange={handleChangeStatus}
                  value={formPaymentData.status}
                >
                  <option value="paid">Paid</option>
                  <option value="unpaid">Unpaid</option>
                </Form.Select>

              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              className="product-form__btn__handleclosemodal"
              onClick={handleUpdatePaymentStatus}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};

export default Transaction;
