import React from 'react';
import { Navigate } from 'react-router-dom';


const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('currentUser'))

const isLoggedIn = token !== null;
function ProtectedSuperAdmin({ children }) {

  return isLoggedIn && user.role=="superadmin" ? children : <Navigate to="/" replace />;
}

export default ProtectedSuperAdmin