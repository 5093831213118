import React, { useEffect, useState } from "react";
import "./ViewAdmin.css";
import {
  Table,
  Dropdown,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { deleteAdmin, getAllAdmin, updateAdmin, updateAdminPassword } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

const ViewAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [adminSelected, setAdminSelected] = useState({});
  const [updatedAdmin, setUpdatedAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [refresh, setRefresh] = useState(false);
  const [adminpasswordtochangeId, setadminpasswordtochangeId] = useState("")
  const [passwordDataForm, setpasswordDataForm] = useState({
    password: ""
  })

  const [showModalPassword, setshowModalPassword] = useState(false)

  useEffect(() => {
    try {
      getAllAdmin().then((res) => {
        if (res.status == 200) {
          setAdmins(res.data);
        }
        return console.log("failed fetch");
      });
    } catch (error) {
      console.log(error);
    }
  }, [refresh]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleUpdateModal = (admin) => {
    setUpdatedAdmin({
      ...admin,
      ["firstName"]: admin.firstName,
      ["lastName"]: admin.lastName,
      ["email"]: admin.email,
    });
    setShowModal(true);
    setAdminSelected(admin);
  };

  const handleUpdatePasswordModal = (adminId)=>{
    setadminpasswordtochangeId(adminId)
    setshowModalPassword(true)
  }

  const handleDeleteAdmin = (adminId) => {
    try {
      deleteAdmin(adminId).then((res) => {
        if (res.status == 200) {
          setRefresh(!refresh);
          setShowModal(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateAdmin = (adminId) => {
    try {
      updateAdmin(adminId, updatedAdmin).then((res) => {
        if (res.status == 200) {
          setRefresh(!refresh);
          setShowModal(false);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleUpdateAdminPassord = () => {
    try {
      updateAdminPassword(adminpasswordtochangeId, passwordDataForm).then((res) => {
        if (res.status == 200) {
          setRefresh(!refresh);
          setshowModalPassword(false);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  };



  const handleChangeModalForm = (event) => {
    const { name, value } = event.target;
    setUpdatedAdmin({ ...updatedAdmin, [name]: value });
  };

  const handlePasswordChangeForm = (event)=>{
    const {name, value} = event.target
    setpasswordDataForm({...passwordDataForm, [name]: value})
  }

  const handleCloseModal = () => {
    // console.log(updatedAdmin);

    setUpdatedAdmin({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
    setAdminSelected({});
    setShowModal(false);
  };

  const handleCloseModalPassword = ()=>{
    setpasswordDataForm({
      password:""
    })
    setshowModalPassword(false)
  }

  return (
    <>
    <Sidebar/>
    <div className="viewadmin">
      <h2 className="purchase-title">List of Admin</h2>

      {/* <Form.Control
        className="w-auto"
        style={{ marginBottom: "10px" }}
        type="text"
        placeholder="Search by name"
      /> */}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>email</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin, index) => (
            <tr key={index}>
              <td>{admin.firstName}</td>
              <td>{admin.lastName}</td>

              <td>{admin.email}</td>

              <td>{formatDate(admin.createdAt)}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    className="product-form__btn__handledeleteandupdate"
                    id="dropdown-basic"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleUpdateModal(admin)}>
                      Update
                    </Dropdown.Item>
                    {admin.role=="admin" && <Dropdown.Item
                      onClick={() => handleDeleteAdmin(admin["_id"])}
                    >
                      Delete
                    </Dropdown.Item>}
                    <Dropdown.Item
                      onClick={() => handleUpdatePasswordModal(admin["_id"])}
                    >
                      Update Password
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Pagination>
        {Array.from({
          length: Math.ceil(products.length / productsPerPage),
        }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === currentPage}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination> */}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="adminName">
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Enter admin firstname"
                name="firstName"
                onChange={handleChangeModalForm}
                value={updatedAdmin.firstName}
              />

              <Form.Label>Lastname</Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter admin lastname"
                name="lastName"
                onChange={handleChangeModalForm}
                value={updatedAdmin.lastName}
              />

              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChangeModalForm}
                placeholder="Enter admin email"
                value={updatedAdmin.email}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            className="product-form__btn__handleclosemodal"
            onClick={() => handleUpdateAdmin(adminSelected["_id"])}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalPassword} onHide={handleCloseModalPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Update Admin Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="adminName">
             

              <Form.Label>Password</Form.Label>

              <Form.Control
                type="password"
                placeholder="Enter admin password"
                name="password"
                onChange={handlePasswordChangeForm}
                value={passwordDataForm.password}
              />

              
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalPassword}>
            Close
          </Button>
          <Button
            className="product-form__btn__handleclosemodal"
            onClick={handleUpdateAdminPassord}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  );
};

export default ViewAdmin;
