import React, { useEffect, useState } from "react";
import { Table, Spinner, Pagination, Form, Button, Dropdown, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import "./Transaction.css";
import { getAllTransaction } from "../../services/Service";
import Sidebar from "../../navs/Sidebar";

const Transfers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
 


  useEffect(() => {
    setIsLoading(true);
    getAllTransaction().then((result) => {
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        const transactionResult = result.data.filter(transaction=>{
           return (transaction.transfer=="transfer")
         } )
        setTransactions(transactionResult);
        // console.log(result.data);
      }
    });
  },[]);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  let filteredProducts = [...transactions];

  if (startDate && endDate) {
    // If a date range is selected, filter transactions based on the date range
    filteredProducts = filteredProducts.filter((transaction) => {
      const createdAtDate = new Date(transaction.createdAt);
      return createdAtDate >= startDate && createdAtDate <= endDate;
    });
  }

  // Aggregating transactions by name and calculating total price
  const aggregatedTransactions = filteredProducts.reduce((acc, transaction) => {
    if (!acc[transaction.name]) {
      acc[transaction.name] = {
        name: transaction.name,
        totalprice: 0,
        details: [],
      };
    }
    acc[transaction.name].totalprice += transaction.totalprice;
    acc[transaction.name].details.push(transaction);
    return acc;
  }, {});

  // If no date range is selected, calculate total prices based on all transactions
  const currentProducts = Object.values(aggregatedTransactions).slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };

  const handleNameClick = (name) => {
    setSelectedName(name);
  };

  const handleBackClick = () => {
    setSelectedName(null);
  };

  const csvData = selectedName !== null
    ? (startDate && endDate
      ? filteredProducts.map(filterdata => ({
        ...filterdata,
        adminId: `${filterdata.adminId.firstName} ${filterdata.adminId.lastName}`
      }))
      : aggregatedTransactions[selectedName].details.map(filterdata => ({
        ...filterdata,
        adminId: `${filterdata.adminId.firstName} ${filterdata.adminId.lastName}`
      }))
    )
    : transactions;


  return (
    <>
      <Sidebar />
      <div className="transaction">
        {isLoading ? (
          <div className="transaction-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="transaction-content">
            <h2 className="purchase-title">List of Transfers</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <div className="button-container">
                <Button variant="success" className="mr-2">
                  <CSVLink
                    data={csvData}
                    filename={"transactions.csv"}
                    className="download-button"
                  >
                    Download Excel
                  </CSVLink>
                </Button>
              </div>
              <Form.Group controlId="dateRangePicker">
                {!selectedName && <Form.Label>Date Range:</Form.Label>}
                {!selectedName && <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  placeholderText="Date Range"

                />}
              </Form.Group>

            </div>

            {selectedName ? (
              <div>
                <Button onClick={handleBackClick} className="back-button">
                  Back
                </Button>
                <Table striped bordered hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Date</th>
                      <th>name</th>
                      <th>color</th>
                      <th>size</th>
                      <th>Transferred quantity</th>
                      <th>Price Per Unit</th>
                      <th>Total Price</th>
                        <th>Transfer</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Done By</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    {aggregatedTransactions[selectedName].details.map(
                      (transaction, index) => (
                        <tr key={transaction._id}>
                          <td>{index + 1}</td>
                          <td>{formatDate(transaction.createdAt)}</td>
                          <td>{transaction.name}</td>
                          <td>{transaction.color}</td>
                          <td>{transaction.size}</td>
                          <td>

                            {transaction.cartons
                              ? `${transaction.cartons} cartons`
                              : transaction.boxes
                                ? `${transaction.boxes} boxes`
                                : `${transaction.pieces} pieces`}
                          </td>
                          <td>{transaction.price}</td>
                          <td>{transaction.totalprice}</td>
                          <td style={{color:"green"}}>{transaction.transfer}</td>
                          



                          <td>{transaction.shop}</td>
                          <td>{transaction.shop=="shop 1"? "shop 2": "shop 1"}</td>

                          <td>{`${transaction.adminId?.firstName} ${transaction.adminId?.lastName}`}</td>
                          
                        </tr>
                      )
                    )}
                    <td colSpan="7">Total Transferred Amount</td>
                    <td >{aggregatedTransactions[selectedName].details.reduce((accumulator, currentTransaction) => (
                      accumulator + currentTransaction.totalprice
                    ), 0)}</td>
                  </tbody>
                </Table>
              </div>
            ) : (
              <Table striped bordered hover id="table-to-xls">
                <thead>
                  <tr>
                    <th onClick={() => handleNameClick(null)}>Name</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {currentProducts.map((transaction) => (
                    <tr key={transaction.name}>
                      <td onClick={() => handleNameClick(transaction.name)}>
                        {transaction.name}
                      </td>
                      <td>{transaction.totalprice}</td>
                    </tr>
                  ))}
                  <td>Total Transferred Amount</td>
                  <td >{currentProducts.reduce((accumulator, currentTransaction) => (
                    accumulator + currentTransaction.totalprice
                  ), 0)}</td>
                </tbody>
              </Table>
            )}
            <Pagination>
              {Array.from({
                length: Math.ceil(
                  Object.values(aggregatedTransactions).length /
                  productsPerPage
                ),
              }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}
      </div>
    </>
  );
};

export default Transfers;
