import { createSlice } from "@reduxjs/toolkit";


const initialState = []

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        clearproductlist:(state)=>{
            state = []
        },

        addproducts: (state, action)=>{
            state = action.payload
           
        },

        removeproduct: (state, action)=>{
            const productId = action.payload
            state = state.filter((product)=>product._id !== productId)
        },
        
    }
})

console.log(productSlice);
export const {clearproductlist, addproducts, removeproduct, getallproducts} = productSlice.actions 
export default productSlice.reducer