import React, { useState } from "react";
import "./Login.css";
import inventory from "../../helpers/stock.jpeg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { getAdminProfile, login } from "../../services/Service";
import { useNavigate } from "react-router";
import Sidebar from "../../navs/Sidebar";

const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChangesInForm = (event) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const [isMessage, setMessage] = useState(false)

  const handleSubmission = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      const res = await login(loginData);
      if (res.status === 200) {
        setLoading(false);
        const token = localStorage.setItem("token", res.data.token);

        const adminProfileRes = await getAdminProfile(localStorage.getItem('token'));
        if (adminProfileRes.status === 200) {
          delete adminProfileRes.data.password;
          const currentUserString = JSON.stringify(adminProfileRes.data);
          localStorage.setItem('currentUser', currentUserString);
          setLoading(false);
          if(adminProfileRes.data.role == "superadmin"){
            return window.location.href = '/purchase';
          }
          return window.location.href = '/sell';
        } else {
          setLoading(false);
          setMessage(true);

          setTimeout(() => {
            setMessage(false);
          }, 5000);
        }
      }
    } catch (error) {

      setLoading(false);
      setMessage(true);

      setTimeout(() => {
        setMessage(false);
      }, 5000);
    }
  };

  return (


    <div className="login">
      <div className="login-container">
        <h5 className="login-title">PRIMAX</h5>
        <div className="login-user">
          <form className="login-form" onSubmit={handleSubmission}>
            <div className="login_data">
              <label>Email</label>
              <input
                autoComplete="off"
                type="text"
                name="email"
                value={loginData.email}
                onChange={handleChangesInForm}
              />
            </div>

            <div className="login_data">
              <label>Password</label>
              <div className="login_data_password">
                <input
                  autoComplete="off"
                  type={ showPassword ?"text" : "password"}
                  name="password"
                  value={loginData.password}
                  onChange={handleChangesInForm}
                />
                 <button type="button" onClick={()=>{setShowPassword(!showPassword)}}>{ showPassword ?"Hide" : "Show"}</button>
            </div>
            </div>

            <button type="submit" className="login_btn" disabled={loading}>
              {loading ? (
                <div class="spinner-grow" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Logins"
              )}
            </button>
          </form>

          {isMessage &&  <div className="purchase-message" class="alert alert-danger" role="alert">
            Invalid credentials
          </div>}
        </div>

        
      </div>
      <div className="login-inventory">
        <img className="login-inventory__photo" src={inventory} />
      </div>
    </div>
  );
};

export default Login;
