import React from "react";
import Product from "./Product";


const ShopOne = ()=>{
    return <>
    <Product shop="one"/>
    </>
}


export default ShopOne