import { useState } from 'react';
import './App.css';
import Sidebar from './navs/Sidebar';
import CustomRoutes from './router';
import { BrowserRouter as Router, useParams} from 'react-router-dom';


function App() {
  
 console.log("here is them",window.location);
  return (
    <Router>
      <div className="App">
      <CustomRoutes/>
    </div>
    </Router>
    
  );
}

export default App;
